<template>
  <div>
    <div class="vx-row mb-12">
     
    </div>
    <div class="vx-row mb-12">
      <div
        v-bind:class="[
          detail ? detailShow + ' vertical-divider' : '',
          detailHide,
        ]"
      >
        <data-table
          :responseData="responseData"
          :propsParams="params"
          :header="header"
          @reloadDataFromChild="reloadData"
        >
          <template slot="tbody">
            <vs-tr :key="indextr" v-for="(tr, indextr) in responseData.payment">
             	<vs-td>
								{{ tr.RhpNumber }}
							</vs-td>
							<vs-td>
								{{ territoryName(tr.TerritoryID) }} 
							</vs-td>
							<vs-td>
								{{ priceFormat(tr.Amount) }}
							</vs-td>
							<vs-td>
								{{ dateFormat(tr.CollectionDate) }}
							</vs-td>
            </vs-tr>
          </template>
        </data-table>
      </div>
      <transition name="detail-fade">
        <div
          v-if="detail"
          v-bind:class="[
            detail ? ' nonfixed vx-col md:w-1/2 w-full mb-base' : '',
            detailHide,
          ]"
        >
          <div>
            <vs-col
              style="padding-bottom: 8px"
              vs-offset="8"
              vs-type="flex"
              vs-justify="rigth"
              vs-align="rigth"
              vs-w="4"
            >
              <vs-button
                size="small"
                v-on:click="closeDetail"
                color="grey"
                icon-pack="feather"
                icon="icon-x-square"
                >Close</vs-button
              >
            </vs-col>
            <create @closeDetail="closeDetail" />
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>
<script>
// import { dataPayment } from "@/services/api/payment";
import moment from "moment";
export default {
  props: {
    selected: Object,
    option: Object,
    depositDate: Object,
    collectionDate: Object,
    territory: Object,
    isReload: Boolean,
  },
  data() {
    return {
      typePayment: ["", "Trade", "Deposit", "Open Deposit", "Credit Note"],
      params: {
        search: "",
        length: 10,
        page: 1,
        order: "desc",
        sort: "created_at",
      },
      header: [
				{
					text: "RHP Number",
					value: "rhp_number",
					// width: '5%'
				},
				{
					text: "Territory",
					value: "territory_id",
				},
				{
					text: "Amount",
					sortable: false,
				},
				{
					text: "Collection Date",
					value: "date_payment",
				},
			],
      responseData: {},
      detailShow: "vx-col md:w-1/2 w-full mb-base",
      detailHide: "core vx-col md:w-1/1 w-full mb-base",
      detail: false,
      selectedData: {},
      checked: [],
      dataChecked: [],
      checkedAll: false,
      paymentDataID: [],
    };
  },
  computed: {},
  watch: {
    // depositDate() {
    //   this.reloadData(this.params);
    // },
    // collectionDate() {
    //   this.reloadData(this.params);
    // },
    // territory() {
    //   this.reloadData(this.params);
    // },
    isReload() {
      if (!this.isReload) {
        return;
      }
      this.reloadData(this.params);
    },
  },
  mounted() {
    // this.getData();
    // this.page(1);
    // this.dataId = this.data[0].id
  },
  methods: {
    create() {
      this.detail = true;
    },
    territoryName(value) {
      
      const filter = this.$store.state.operatingUnit.filter.territory_single.filter(v => {
        return v.TerritoryID == value
      })

      if (filter.length > 0) {
        return filter[0].Code +" "+ filter[0].Name
      } else {
        return "-" + filter
      }
      
    },
    checkCollection(obj, index) {
			console.log(obj, index);
			// console.log(this.paymentDataID, 'payment');
			if (this.checked.includes(obj)) {
				this.checked.splice(this.checked.indexOf(obj), 1);
				this.dataChecked = this.dataChecked.filter((v) => {
					return v.RhpNumber+v.CollectionDate !=obj;
				});
			} else {
				this.checked.push(obj);
				this.dataChecked.push(this.data[index]);
			}
		},
    dateFormat(value) {
      return moment(String(value)).format("DD/MM/YYYY");
    },
    closeDetail(params = false) {
      window.scrollTo(0, 0);
      this.detail = false;
      this.selectedData = {
        ID: 0,
      };
      if (params == true) {
        this.reloadData(this.params);
      }
    },
    dataTable(params) {
      console.log(params, "dataTable param");
      return new Promise((resolve, reject) => {
        this.$http
          .get("/api/v1/payment/data-table/cit/group", {
            params: {
              search: params.search,
              length: params.length,
              page: params.page,
              order: params.order,
              sort: params.sort,
              territory_ids: params.territory_ids,
              status: 6,
              type: 1,
              bank_id: 0,
              is_non_cash: false,
              territory_id: this.territory ? this.territory.id : null,
              start_collect_date: this.collectionDate.startDate
                ? moment(this.collectionDate.startDate).format("YYYY-MM-DD")
                : null,
              end_collect_date: this.collectionDate.endDate
                ? moment(this.collectionDate.endDate).format("YYYY-MM-DD")
                : null,
              start_deposit_date: this.depositDate.startDate
                ? moment(this.depositDate.startDate).format("YYYY-MM-DD")
                : null,
              end_deposit_date: this.depositDate.endDate
                ? moment(this.depositDate.endDate).format("YYYY-MM-DD")
                : null,
            },
          })
          .then((r) => {
            resolve(r);
          })
          .catch((e) => {
            reject(e);
          });
      });
    },
    reloadData(params) {
      const territory_ids =
        this.$store.getters["operatingUnit/getSelectedTerritoryId"];
      this.params = {
        ...params,
        territory_ids,
      };
      console.log(this.params, "params");
      this.$vs.loading();

      const dataTable = this.dataTable(this.params);
      dataTable.then((r) => {
        console.log(r);
        if (r.code == 500) {
          this.$vs.loading.close();
        } else if (r.code == 200) {
          this.$vs.loading.close();
          this.data = r.data.payment;
          this.responseData = r.data;
          this.responseData.length = r.data.payment.length;
          // this.checkedAll = false;
          // this.checked = [];
          // this.dataChecked = [];
        } else {
          this.$vs.loading.close();
        }
      });
      // this.getPaymentData(this.params)
    },
    getPaymentData(params) {
      this.params = params;
      console.log(this.params, "params");
      this.$vs.loading();

      this.$http
        .get("/api/v1/payment/check-all", {
          params: {
            search: params.search,
            length: params.length,
            page: params.page,
            order: params.order,
            sort: params.sort,
            status: 6,
            type: 1,
            bank_id: 0,
            territory_id: this.territory ? this.territory.id : null,
            start_collect_date: this.collectionDate.startDate
              ? moment(this.collectionDate.startDate).format("YYYY-MM-DD")
              : null,
            end_collect_date: this.collectionDate.endDate
              ? moment(this.collectionDate.endDate).format("YYYY-MM-DD")
              : null,
            start_deposit_date: this.depositDate.startDate
              ? moment(this.depositDate.startDate).format("YYYY-MM-DD")
              : null,
            end_deposit_date: this.depositDate.endDate
              ? moment(this.depositDate.endDate).format("YYYY-MM-DD")
              : null,
          },
        })
        .then((r) => {
          console.log(r);
          if (r.code == 500) {
            this.$vs.loading.close();
          } else if (r.code == 200) {
            this.$vs.loading.close();
            console.log("dataPayment: ", r.data);
            this.paymentDataID = r.data.paymentDataID;
          } else {
            this.$vs.loading.close();
          }
        });
    },
    check(val, index) {
      console.log(val, index);
      console.log(this.paymentDataID, "payment");
      if (this.checked.includes(val)) {
        this.checked.splice(this.checked.indexOf(val), 1);
        this.dataChecked = this.dataChecked.filter((v) => {
          return v.ID != val;
        });
      } else {
        this.checked.push(val);
        this.dataChecked.push(this.data[index]);
      }
    },
    addAllChecked: function () {
      if (!this.checkedAll) {
        for (let i = 0; i < this.data.length; i++) {
          if (!this.checked.includes(this.data[i].RhpNumber + this.data[i].CollectionDate)) {
            this.checked.push(this.data[i].RhpNumber + this.data[i].CollectionDate);
            this.dataChecked.push(this.data[i]);
          }
        }
      } else {
        this.checked = [];
        this.dataChecked = [];
      }
    },
    validate() {
      if (this.checked.length < 1) {
        // console.log("jalan: Generating");
        // console.log(this.responseData, "payment");
        // console.log(this.checked, "checked");
        this.$vs.notify({
          title: "Error",
          text: "You must choose a payment",
          color: "danger",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-check",
        });
        return false;
      }

      
      return true;
    },
    generateCashBank() {
      if (!this.validate()) {
        console.log("jalan: Generating");
        return;
      }
      console.log(this.dataChecked);
      this.$vs.loading();
      let lines = [];
      let rhpData = [];
      let refCode = "";
      let bankID = 0;
      let territoryID = 0;
      let territoryAreaID = 0;
      let BankName = "";
      let PaymentMethod = "";
      let value = 0;
      let collectionDate = "";
      this.dataChecked.map((row, index, arr) => {
        rhpData.push({
          CollectionDate: row.CollectionDate,
          Amount: row.Amount,
          RhpNumber: row.RhpNumber,
        })

        // row.PaymentID to array
        row.PaymentID.split(",").map((val) => {
          lines.push({
            payment_id: Number(val),
            amount: row.Amount,
          });
        });
        if (index + 1 === arr.length) {
          refCode += row.RhpNumber
        } else {
          refCode += row.RhpNumber + "/"
        }
        // lines.push({
        //   payment_id: row.ID,
        //   amount: row.Amount,
        // });

        value += row.Amount;
        bankID = 0;
        territoryID = row.TerritoryID;
        territoryAreaID = row.TerritoryAreaID;
        PaymentMethod = row.PaymentMethod;
        collectionDate = row.CollectionDate;
        if (bankID > 0) {
          BankName = row.BankName;
        }
      });
      console.log(rhpData);

      const params = {
        line: lines,
        referenceCode: refCode,
        bankID: bankID,
        territoryID: territoryID,
        territoryAreaID: territoryAreaID,
        paymentMethod: PaymentMethod,
        collectionDate: collectionDate,
        value: value,
        rhpData: rhpData,
      };

      if (bankID > 0) {
        params["bank_name"] = BankName;
      }
      this.$http
        .post("/api/v1/cash-bank/for-payment/lines", params)
        .then((resp) => {
          this.$vs.loading.close();
          console.log(resp.code, "resp.code ");
          if (resp.code == 200) {
            // this.handleClose()
            this.checked = [];
            this.dataChecked = [];
            this.reloadData(this.params);
            this.$vs.notify({
              title: "Success",
              text: "",
              color: "success",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-check",
            });
          } else {
            this.$vs.notify({
              title: "Error",
              text: resp.message,
              color: "danger",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-check",
            });
          }
        })
        .catch((e) => {
          this.$vs.notify({
            title: "Error",
            text: "Failed to create",
            color: "danger",
            position: "top-right",
            iconPack: "feather",
            icon: "icon-check",
          });
        });
    },
  },
};
</script>
<style scoped>
.nonfixed {
  position: inherit;
  padding-left: 20px;
}
.core-enter-active {
  transition: all 0.3s ease;
}
.core-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}
.core-enter, .core-leave-to
  /* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}
.core {
  -webkit-transform-origin-y: all 1s ease;
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -o-transition: all 1s ease;
  transition: all 1s ease;
}
.vs-con-table .vs-con-tbody .vs-table--tbody-table {
  font-size: 12px;
}
.vertical-divider {
  border-right: 1px solid #7367f0;
  /* min-height: 800px; */
  /* height: 100%; */
  -webkit-mask-position-y: fixed;
  /* padding: 5px; */
}
.vs-con-table.stripe .tr-values:nth-child(2n) {
  background: beige;
}
.colored {
  border: 1px solid #7367f0;
  position: fixed;
  left: 4%;
  top: 40%;
  max-width: 45%;
  z-index: 999999999999;
  background: antiquewhite;
  background-color: antiquewhite;
}
</style>
